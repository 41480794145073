<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <TrainForm ref="trainForm" :initial="initial" v-show="edit" class="area-content"></TrainForm>
      <div class="area-content" v-if="detail" v-show="!edit">
        <Descriptions title="课程基本信息" :list="basicList" :detail="detail" class="mb-24"></Descriptions>
        <template v-if="detail.form == 2">
          <div>
            <div class="ant-descriptions-title">核销人管理</div>
            <a-table ref="dataTable" :columns="columns" :row-key="(record) => record.id" :data-source="list"> </a-table>
          </div>
          <Descriptions
            title="报名限制条件"
            :list="limitList"
            :detail="detail"
            v-if="detail.form == 2"
            class="mb-24"
          ></Descriptions>
          <a-descriptions :column="1">
            <a-descriptions-item label="仅以下志工可报名">
              <VolunteerList :info="detail" :show-label="false"></VolunteerList>
            </a-descriptions-item>
            <a-descriptions-item label="仅已选中的小组可参与">
              <LimitGroupList :info="detail" :type="2"></LimitGroupList>
            </a-descriptions-item>
          </a-descriptions>
        </template>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </template>
      <template v-else>
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handleEdit">编辑</a-button>
      </template>
    </a-space>
  </div>
</template>

<script>
import {
  flagOptions,
  teachingFormatOptions,
  coursewareOptions,
  numberOptions,
  catOptions,
  volunStatusOptions,
} from "../../../common/hr/volunteer";
import { isEmpty, timestampConvertString } from "../../../common/js/tool";
import TrainForm from "./components/TrainForm.vue";
import Descriptions from "./components/Descriptions.vue";
import { getSystemRoleName } from "../../../permission/subsystem";
import VolunteerList from "./components/VolunteerList.vue";
import LimitGroupList from "./components/LimitGroupList.vue";

export default {
  name: "TrainDetail",
  components: {
    TrainForm,
    Descriptions,
    VolunteerList,
    LimitGroupList,
  },
  data() {
    return {
      edit: false,
      detail: null,
      initial: null,
      basicList: [],
      limitList: [],
      columns: [
        {
          title: "核销人名称",
          dataIndex: "user_id",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return <open-data type="userName" openid={text[0]} />;
            } else {
              return "-";
            }
          },
        },
        { title: "手机号码", dataIndex: "mobile" },
        {
          title: "核销类别",
          customRender: (text) => {
            return <span>{getSystemRoleName(text.system, text.privilege)}</span>;
          },
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
        showSizeChanger: true,
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-train-list/${this.id}`,
          method: "GET",
          params: {
            expand: "trainType,userRole,joinGroup",
          },
        }).then((res) => {
          this.detail = res;
          if (this.$route.query.edit) {
            this.handleEdit();
          } else {
            this.getDescriptionsList();
          }
        });
      }
    },
    getDescriptionsList() {
      let { form, is_set_time, courseware_type, is_limit, userRole, is_exam } = this.detail;
      let basicList = [];
      let limitList = [];
      if (form == 1) {
        // 线上
        basicList.push(
          {
            key: "name",
            label: "课程名称",
          },
          {
            label: "课程类型",
            slot: (detail) => {
              return <span>{detail?.trainType?.name}</span>;
            },
          },
          {
            key: "form",
            label: "授课形式",
            options: teachingFormatOptions,
            select: true,
          },
          {
            key: "is_exam",
            label: "是否有考试",
            options: flagOptions,
            select: true,
          },
          {
            key: "is_set_time",
            label: "是否设置考试入口开放时间",
            options: flagOptions,
            select: true,
          }
        );
        if (is_set_time == 2) {
          basicList.push({
            key: "opening_hours",
            label: "考试入口开放时间",
            timestamp: true,
          });
        }
        basicList.push(
          {
            key: "course_num",
            label: "课程编号",
          },
          {
            key: "courseware_type",
            label: "课件内容类型",
            options: coursewareOptions,
            select: true,
          }
        );
        switch (courseware_type) {
          case 1:
            basicList.push({
              key: "courseware",
              label: "直播链接",
            });
            break;
          case 2:
            basicList.push({
              key: "courseware",
              label: "视频",
              media: true,
            });
            break;
          case 3:
            basicList.push({
              key: "courseware",
              label: "图文",
              rich: true,
            });
            break;
          case 4:
            basicList.push({
              key: "pdf",
              label: "PDF",
              file: true,
            });
            break;
          default:
            basicList.push({
              key: "courseware",
              label: "直播链接",
            });
        }
        basicList.push({
          key: "course",
          label: "课程介绍",
          rich: true,
        });
        this.basicList = basicList;
      } else if (form == 2) {
        // 线下
        basicList.push(
          {
            key: "name",
            label: "课程名称",
          },
          {
            label: "课程类型",
            slot: (detail) => {
              return <span>{detail?.trainType?.name}</span>;
            },
          },
          {
            key: "form",
            label: "授课形式",
            options: teachingFormatOptions,
            select: true,
          },
          {
            key: "is_exam",
            label: "是否有考试",
            options: flagOptions,
            select: true,
          },
          {
            label: "培训地点",
            slot: (detail) => {
              let { train_place, train_address } = detail;
              return <span>{train_place?.replace(/,/g, "") + train_address}</span>;
            },
          },
          {
            label: "可打卡时间",
            slot: (detail) => {
              let { clock_in_start_time, clock_in_end_time } = detail;
              return (
                <span>
                  {timestampConvertString(clock_in_start_time) + " ~ " + timestampConvertString(clock_in_end_time)}
                </span>
              );
            },
          }
        );
        if (is_exam == 1) {
          basicList.push(
            {
              label: "考试地点",
              slot: (detail) => {
                let { exam_place, exam_address } = detail;
                return <span>{exam_place?.replace(/,/g, "") + exam_address}</span>;
              },
            },
            {
              key: "exam_time",
              label: "考试时间",
              timestamp: true,
            }
          );
        }
        basicList.push(
          {
            key: "course_num",
            label: "课程编号",
          },
          {
            key: "is_limit",
            label: "名额限制",
            options: numberOptions,
            select: true,
          }
        );
        if (is_limit == 2) {
          basicList.push({
            key: "limit_people",
            label: "名额数量",
          });
        }
        basicList.push({
          key: "course",
          label: "课程介绍",
          rich: true,
        });
        limitList.push(
          {
            key: "confirm_role",
            label: "可确认志工报名名单的人",
            options: catOptions,
            tag: true,
          },
          {
            key: "volunteer_status",
            label: "可进行报名的志工状态",
            options: volunStatusOptions,
            tag: true,
          },
          {
            label: "可进行报名的年龄",
            slot: (detail) => {
              let { min_age, max_age } = detail;
              if (!isEmpty(min_age) && !isEmpty(max_age)) {
                return (
                  <div>
                    <span>{min_age}岁</span>
                    <span> ~ </span>
                    <span>{max_age}岁</span>
                  </div>
                );
              } else if (!isEmpty(min_age)) {
                return <span>最小{min_age}岁</span>;
              } else if (!isEmpty(max_age)) {
                return <span>最大{max_age}岁</span>;
              } else {
                return <span>-</span>;
              }
            },
          }
        );
        this.limitList = limitList;
        this.basicList = basicList;
        this.list = userRole;
      }
    },
    handleEdit() {
      this.edit = true;
      this.initial = this.detail;
    },
    handleSave() {
      this.$refs.trainForm.validate().then((form) => {
        this.$axios({
          url: `/admin/volunteer-train-list/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.getDetail();
          this.handleCancel();
        });
      });
    },
    handleCancel() {
      if (this.$route.query.edit) {
        this.back();
      } else {
        this.edit = false;
        this.initial = null;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
