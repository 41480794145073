<template>
  <a-table :columns="columns" row-key="id" :data-source="list" class="group-table"></a-table>
</template>

<script>
export default {
  name: "LimitGroupList",
  props: {
    info: Object,
    type: Number,
  },
  data() {
    return {
      columns: [
        {
          title: "小组名称",
          customRender: (text) => {
            return <span>{text.volunteerGroup?.name || "-"}</span>;
          },
        },
        { title: "最多报名人数", dataIndex: "enrollment" },
      ],
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      if (this.info) {
        this.$axios({
          url: "/admin/volunteer-join-group",
          method: "GET",
          params: {
            "filter[type]": this.type,
            expand: "volunteerGroup",
            "filter[content_id]": this.info.id,
          },
        }).then((res) => {
          this.list = res.data;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.group-table {
  margin-top: 6px;
}
</style>
